import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PostDisplay from "../components/PostDisplay"
import '../styles/blog.scss'

/** Requête graphql pour afficher l'ensemble des articles du blog */
const getPosts = graphql`
{
    allContentfulBlogArticle(sort: {fields: pubDate, order: DESC}) {
      edges {
        node {
          title
          slug
          pubDate(formatString: "DD MMM YYYY", locale: "fr")
          excerpt {
            excerpt
          }
          featImage {
            id
            title
            file{
              url
            }
            fluid{
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

/** Component page blog */
const BlogPage = () => {
  
    const data = useStaticQuery(getPosts)
    if(data.allContentfulBlogArticle.edges === 0)
    {
      return (
        <Layout theme="blog">
          <SEO title="Design. Web. Tech. Actus."/>
            <h1 className="page-main-title"><span className="thin">Espace</span> blog</h1>
            <p>Oups, il n'y a pas encore d'article...</p>
        </Layout>
      );
    }

    return (
        <Layout theme="blog">
          <SEO title="Design. Web. Tech. Actus."/>
            <h1 className="page-main-title"><span className="thin">Espace</span> blog</h1>
            <h3 className="sous-titre">Actus. Technologies. Design.</h3>
            <section className="blog-section">

                {data.allContentfulBlogArticle.edges.map((edge => {

                  if(edge === data.allContentfulBlogArticle.edges[0]){
                    return (

                      <PostDisplay 
                        key={edge.node.slug}
                        title={edge.node.title}
                        pubDate={edge.node.pubDate}
                        slug={edge.node.slug}
                        excerpt={edge.node.excerpt.excerpt ? edge.node.excerpt.excerpt : "Extrait indisponible."}
                        src={edge.node.featImage.fluid}
                        alt={edge.node.featImage.title}
                        alaune="alaune alaune-de-blog"
                      />
                    )
                  }
                    return (

                      <PostDisplay 
                        key={edge.node.slug}
                        title={edge.node.title}
                        pubDate={edge.node.pubDate}
                        slug={edge.node.slug}
                        excerpt={edge.node.excerpt.excerpt ? edge.node.excerpt.excerpt : "Extrait indisponible."}
                        src={edge.node.featImage.fluid}
                        alt={edge.node.featImage.title}
                        
                      />
                    )
                }))}
            </section>

        </Layout>
    );
};

export default BlogPage;